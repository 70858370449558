<template>
  <div class="responsive-bar">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <b-modal
      class="p-2 modal-content"
      id="report-found-modal"
      hide-header-close
      hide-header
      hide-footer
      centered
      v-if="assetData"
    >
      <div class="row" v-if="!isLoading">
        <h5
          class="modal-title text-center"
          v-html="
            $t('detailasset.re_found_asset', {
              assetId: assetData.id_fixed_asset,
            })
          "
        ></h5>
      </div>
      <label>{{ $t("asset.note") }}</label>
      <textarea class="comment-textarea w-100" v-model="note_lost"></textarea>
      <div class="row justify-content-center">
        <button
          type="button"
          class="btn btn-secondary col-6"
          @click="
            note_lost = '';
            $bvModal.hide('report-found-modal');
            $router.back();
          "
        >
          {{ $t("btn.canc") }}
        </button>
        <button
          type="button"
          class="btn btn-primary col-6"
          @click="reportFound"
          :disabled="!note_lost"
        >
          {{ $t("btn.conf") }}
        </button>
      </div>
    </b-modal>
    <Footer></Footer>
    <div class="scan-qr">
      <div class="">
        <h1 class="header-text">
          <div class="topic">{{ $t("scan.scanqr") }}</div>
        </h1>
      </div>
      <div class="pt-4">
        <div class="line-break"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Loading from "vue-loading-overlay";
import Swal from "sweetalert2";
import baseUrl from "@/util/backend";
import axios from "axios";
import { authHeader } from "@/store/actions";

import base62 from "base62/lib/ascii";

export default {
  data() {
    return {
      note_lost: "",
      isNative: false,
      isLoading: false,
      error: "",
      page: "",
      waitConfirm: false,
      assetData: null,
    };
  },
  components: { Footer, Loading },

  created() {
    if (window.isNativeApp) {
      this.isNative = true;
    }
  },
  async mounted() {
    this.id_company = await this.$store.dispatch("getCompanyIdCookie");
    this.page = this.$route.params.page;
    this.$store.commit("getDetailAssetsMutations", "");
    this.check();
  },
  methods: {
    async reportFound() {
      this.$bvModal.hide("report-found-modal");
      this.isLoading = true;
      let asset = {
        id_asset: this.assetData.id_asset,
        is_lost: "report_found",
        note_lost: this.note_lost,
      };
      await this.$store.dispatch("ChangeLostStatus", asset);
      Swal.fire({
        icon: "success",
        title: this.$t("dialog.success"),
        showConfirmButton: false,
        timer: 3000,
      });
      this.isLoading = false;
      this.$router.back();
    },

    async check() {
      this.isLoading = true;
      await this.checkFromQr(this.$route.params.qrAssetId);
      if (this.assetData === null) {
        await Swal.fire({
          title: this.$t("maintenance_report.invalidQr-title"),
          text: this.$t("maintenance_report.invalidQr-text"),
          timer: 3000,
          imageUrl: "https://api.verscan.com/download/sweet-alert-alert.svg",
          confirmButtonText: this.$t("maintenance_report.close"),
          imageHeight: 48,
          imageWidth: 48,
        });
        this.$router.back();
      } else if (
        this.assetData.asset_status == "maintain" ||
        this.assetData.asset_status == "broke"
      ) {
        console.log(this.assetData.maintain_detail);
        this.$router.push({
          name: this.$route.query.callback,
          query: { maintain: this.assetData.maintain_detail.id_maintain },
        });
      } else if (this.assetData.is_lost === "lost") {
        this.$bvModal.show("report-found-modal");
      } else if (this.assetData.is_lost === "report_found") {
        await Swal.fire({
          title: this.$t("dialog.asset_found_reporting"),
          timer: 3000,
        });
        this.$router.back();
      } else {
        console.log(this.assetData);
        this.$router.push({
          name: this.$route.query.callback,
          params: { assetId: this.assetData.id_asset },
        });
      }
      this.isLoading = false;
    },

    async checkFromQr(qrCode) {
      this.isLoading = true;
      try {
        const res = await axios.post(
          `${baseUrl()}counting`,
          { id_qrcode: qrCode },
          {
            params: {
              id_company: this.id_company,
            },
            ...authHeader(),
          }
        );
        this.assetData = res.data.data.asset;
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header-text {
  padding-top: 58px;
  height: 173px;
  background: transparent linear-gradient(180deg, #ffa800 0%, #ea7509 100%);
  margin: auto;

  h1 {
    font: normal normal bold 44px/48px Mitr;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    // margin-bottom: 15rem;
  }
}
.topic {
  padding-top: 2.7rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
}

.swal2-icon.swal2-warning {
  border-color: red !important;
  color: red !important;
}
.swal2-styled.swal2-confirm {
  font-size: 14px;
}
.swal2-title {
  font-size: 14px !important;
  color: #000 !important;
}
.swal2-html-container {
  font-size: 12px !important;
  color: #7c7c7c !important;
  margin-top: 5px;
}
.swal2-popup {
  border-radius: 10px !important;
  padding: 0.75rem;
}
.swal2-styled.swal2-confirm {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  border-radius: 8px !important;
}
</style>
